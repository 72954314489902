
import { defineComponent } from 'vue';
import SimpleDatePopover from '@/components/SimpleDatePopover.vue';
import NotificationStoreEvents from '@/constants/NotificationStoreEvents';
import ShippingNotice from '@/domain/ShippingNotice';
import ImportErrorTable from '@/modules/master-data/views/import-errors/components/ImportErrorTable.vue';
import ShippingNoticeService from '@/services/ShippingNoticeService';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import { ColumnDefinition } from '@/types';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';

export default defineComponent({
    name: 'advanced-shipping-notice-import-errors-screen',
    components: {
        ImportErrorTable,
        SimpleDatePopover,
    },
    setup() {
        const shippingNoticeService = new ShippingNoticeService();

        const updateDataRoute = MasterDataRouteTypes.IMPORT_ERRORS.UPDATE_ASN;

        const tableColumns: ColumnDefinition<ShippingNotice>[] = [
            {
                key: 'fromLocation',
                label: getTitleCaseTranslation('core.domain.fromLocation'),
            },
            {
                key: 'toLocation',
                label: getTitleCaseTranslation('core.domain.toLocation'),
            },
            {
                key: 'partnerReferenceNumber',
                label: getTitleCaseTranslation('core.domain.partnerReferenceNumber'),
            },
            {
                key: 'shipDate',
                label: getTitleCaseTranslation('core.domain.shipDate'),
            },
            {
                key: 'carrier',
                label: getTitleCaseTranslation('core.domain.carrier'),
            },
            {
                key: 'trailerNumber',
                label: getTitleCaseTranslation('core.domain.trailer'),
            },
            {
                key: 'carrierReferenceNumber',
                label: getTitleCaseTranslation('core.domain.carrierReferenceNumber'),
            },
            {
                key: 'licensePlateNumber',
                label: getTitleCaseTranslation('core.domain.licensePlateNumber'),
            },
            {
                key: 'seal',
                label: getTitleCaseTranslation('core.domain.seal'),
            },
            {
                key: 'createdOn',
                label: getTitleCaseTranslation('core.domain.createdOn'),
            },
            {
                key: ['container'],
                label: getTranslation('core.domain.partItemNumber'),
            },
            {
                key: ['containerQty'],
                label: getTranslation('core.domain.partItemQuantity'),
            },
        ];

        return {
            getTitleCaseTranslation,
            NotificationStoreEvents,
            shippingNoticeService,
            tableColumns,
            updateDataRoute,
        };
    },
});
