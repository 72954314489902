
import {
    computed, defineComponent, onBeforeMount, PropType, reactive,
} from 'vue';
import { useRouter } from 'vue-router';
import BButton from '@/components/bootstrap-library/BButton.vue';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import Screen from '@/components/layout/Screen.vue';
import ImportError from '@/domain/ImportError';
import ImportErrorService from '@/services/ImportErrorService';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import { CoreStore } from '@/store/CoreStore';
import { ColumnDefinition, TableDefinition } from '@/types';

interface State {
    importErrorMsgs: string[];
    importErrors: ImportError[];
    loading: boolean;
    showImportErrorMsgs: boolean;
}

export default defineComponent({
    name: 'import-error-table',
    components: {
        BButton,
        BDropdown,
        BDropdownItem,
        BModal,
        BAdvancedTable,
        Screen,
    },
    props: {
        importErrorService: {
            type: Object as PropType<ImportErrorService<ImportError>>,
            required: true,
        },
        pushNotificationSubscriptionKey: {
            type: String,
            required: true,
        },
        tableColumns: {
            type: Array as PropType<ColumnDefinition<any>[]>,
            required: true,
        },
        tableKey: {
            type: String,
            required: true,
        },
        tableTitle: {
            type: String,
            required: true,
        },
        updateDataRoute: {
            type: String,
            default: undefined,
        },
    },
    setup(props, { slots }) {
        const { confirm } = useDialogBox();
        const router = useRouter();

        const { pushNotificationStore } = CoreStore.getInstance();
        const state = reactive<State>({
            importErrorMsgs: [],
            importErrors: [],
            loading: false,
            showImportErrorMsgs: false,
        });

        const table = computed<TableDefinition<any>>(() => ({
            items: state.importErrors,
            key: props.tableKey,
            name: props.tableTitle,
            columnDefinition: props.tableColumns,
        }));

        const tableCellSlotNames = computed(() => {
            const cellSlotNameRegex = /cell\(.+\)/;

            return Object.keys(slots).filter((slotName) => cellSlotNameRegex.test(slotName));
        });

        async function refreshImportErrors(beforeRefresh?: () => Promise<void>) {
            state.loading = true;

            if (beforeRefresh) {
                await beforeRefresh();
            }

            state.importErrors = await props.importErrorService.getAllFailed();
            pushNotificationStore.publish(props.pushNotificationSubscriptionKey, state.importErrors.length);
            state.loading = false;
        }

        async function rejectImportError(row: ImportError) {
            const rejectConfirmResp = await confirm({
                message: getTranslation('core.common.areYouSureYouWantToRejectThis'),
                title: getTitleCaseTranslation('core.common.reject'),
            });

            if (rejectConfirmResp) {
                await refreshImportErrors(async () => {
                    await props.importErrorService.rejectFailed(row.id);
                });
            }
        }

        async function revalidateAllImportErrors() {
            await refreshImportErrors(async () => {
                await props.importErrorService.revalidateAllFailed();
            });
        }

        async function revalidateImportError(row: ImportError) {
            await refreshImportErrors(async () => {
                const importErrMsgs = await props.importErrorService.revalidateFailed(row.id);

                if (importErrMsgs.length > 0) {
                    state.importErrorMsgs = importErrMsgs;
                    state.showImportErrorMsgs = true;
                }
            });
        }

        function goToUpdateData(row: ImportError) {
            if (props.updateDataRoute) {
                router.push({ name: props.updateDataRoute, params: { id: row.id } });
            }
        }

        onBeforeMount(async () => {
            await refreshImportErrors();
        });

        return {
            getTitleCaseTranslation,
            rejectImportError,
            revalidateAllImportErrors,
            revalidateImportError,
            state,
            table,
            tableCellSlotNames,
            goToUpdateData,
        };
    },
});
